import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
// import { useSelector, useDispatch } from 'react-redux'
// import {setUser, setToken} from "../slicers/UserSlice"
import { Link, useNavigate } from "react-router-dom";
// import { BACKEND_LOCATION } from "../config";
import Layout from "./Layout";
// import Alert from "react-bootstrap/Alert";
import "./style.css";
import { setBaseUrl } from "../config";
import { NavbarContext } from "../Context/NavbarContext";
import { PermissionContext } from "../Context/PermissionsContext";
import OuterLoader from "../pages/OuterLoder";
import backgVideo from "../assets/videos/Access-banner.mp4";
import DurbinLogo from "../assets/images/DurbinLogo.jpg"
import useLocalstorage from "../utils/useLocalstorage";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
const Login = () => {
  const [navState, setNavState] = useContext(NavbarContext);
  const [perms, setPerms] = useContext(PermissionContext);
  const {setValues, getValues} = useLocalstorage();
  useEffect(() => {
    setNavState(1);
  }, []);
  const formData = new FormData();
  const navigate = useNavigate();
  // const dispatch = useDispatch()
  const [data, setData] = React.useState({ email: "", password: "" });
  const [show, setShow] = React.useState(false);
  const [errormsg, setErrormsg] = React.useState("");
  const [visible, setVisible] = React.useState(true);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    checkIfLoggedIn();
  }, []);

  const checkIfLoggedIn = () => {
    let isLoggedIn = sessionStorage.getItem("token");
    if (isLoggedIn) {
      navigate("/assets");
    } else {
      return;
    }
  };

  const setPermissions = async (token_id) => {
    console.log(token_id);
    try {
      const { data } = await axios.get(
        `${setBaseUrl}/company/view-permissions`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": token_id,
          },
        }
      );
      console.log(data);
      if (data.permissions) {
        setValues(data.permissions)
        setPerms(data.permissions);
      }
      if (
        (data.message !== undefined) &
        (data.message === "valid token is missing")
      ) {
        return false;
      } else return true;
    } catch (error) {
      console.error(error);
    }
  };

  const LoginFunction = async () => {
    let getPermsSuccess = true;
    try {
      setLoader(true);
      const res = await axios.post(`${setBaseUrl}/company/login`, {
        email: data.email,
        password: data.password,
      });
      console.log(res.data);
      getPermsSuccess = await setPermissions(res.data.token);
      if (getPermsSuccess == false) {
        console.log(getPermsSuccess);
        return;
      }
      sessionStorage.setItem("token", res.data.token);
      sessionStorage.setItem("user", data.email);
      sessionStorage.setItem("asset_tracker_logged_in", "true");
    } catch (error) {
      alert(error.message);
    } finally {
      if (getPermsSuccess == false) {
        setLoader(false);
        return;
      }
      // let perms = JSON.parse(sessionStorage.getItem("permissions"));
      let perms = getValues()
      setLoader(false);
      if (perms.indexOf("ADMIN.ALL") !== -1) {
        navigate("/assets");
      } else if (
        perms.indexOf("ASSETS.ALL") !== -1 ||
        perms.indexOf("ASSETS.VIEW") !== -1 ||
        perms.indexOf("ASSETS.CRU") !== -1
      ) {
        console.log("suman");
        navigate("/assets");
      } else if (
        perms.indexOf("MAINT.ALL") !== -1 ||
        perms.indexOf("MAINT.VIEW") !== -1 ||
        perms.indexOf("MAINT.CRU") !== -1
      ) {
        console.log("suman");
        navigate("/maintenance");
      } else if (
        perms.indexOf("LEASE.ALL") !== -1 ||
        perms.indexOf("LEASE.VIEW") !== -1 ||
        perms.indexOf("LEASE.CRU") !== -1
      ) {
        navigate("/lease");
      } else if (
        perms.indexOf("OPERATOR.ALL") !== -1 ||
        perms.indexOf("OPERATOR.VIEW") !== -1 ||
        perms.indexOf("OPERATOR.CRU") !== -1
      ) {
        navigate("/operators");
      }
    }
  };

  const showPassword = () => {
    let passType = document.getElementById("password");
    if (passType.type === "password") {
      passType.type = "text";
      setVisible(false);
    } else {
      passType.type = "password";
      setVisible(true);
    }
  };

 
  if (loader) {
    return <OuterLoader />;
  } else {


    return (
      
<>
        <div className="flex justify-center items-center h-screen text-xs">
          <div className="relative w-full h-full">
          <video className="w-full h-full" src={backgVideo} autoPlay id="heroVideo" loop muted />
          <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center bg-black bg-opacity-20">
        <form className="bg-[#fff] text-black p-8 rounded-lg shadow-md">
          <img
            src={DurbinLogo}
            alt="Durbin Logo"
            className="w-24 h-24 ml-20 rounded-full items-center justify-center"
          />
          <h1 className="text-center items-center flex justify-center font-extrabold text-[#717171]">Asset Tracker</h1>
          <br />
          <div className="text-sm font-bold  text-[#006DB6] mb-4">
            Company Login
          </div>


          <div className="mb-4 text-xs">
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              onChange={(e) => {
                setData({ ...data, email: e.target.value });
              }}
              className="w-full px-4 py-2 border border-none rounded-lg focus:outline-none focus:border-none focus:ring-0"
            />
          </div>
          <div className="mb-4 flex rounded-lg  ">
            <input
              type="password"
              name="password"
              id="password"
              placeholder="Password"
              onChange={(e) => {
                setData({ ...data, password: e.target.value });
              }}
              
              className="w-full  px-2 py-2    border-none rounded-l-lg focus:ring-0 focus:outline-none focus:border-none"
              >
              </input>
            <div className="bg-[#edebeb] rounded-r-lg border-none ">
            {visible ? 
            (
              <RemoveRedEyeOutlinedIcon className="items-center m-2 justify-center relative text-gray-600  "
              onClick={showPassword}/>
            ):(
              <VisibilityOffOutlinedIcon className="items-center m-2 justify-center relative text-gray-600 " 
              onClick={showPassword}/>
            )  
          }
          </div>                
             
             
          </div>
          <button
            type="submit"
           
            className="w-full font-extrabold bg-[#006DB6] text-white py-3 rounded-lg "
            onClick={LoginFunction}
          >
            Login
          </button>
        </form>
      </div>
  
  
          </div>
        </div>
</>
    

    );
  }
};

export default Login;
