import axios from "axios";
import React, { useEffect, useState } from "react";
import Loader from "../../Loader";
import { CurrentItems } from "./CurrentItems";
import { setBaseUrl } from "../../../config";
import { Pagination } from "../Pagination";
import { useNavigate } from "react-router-dom";
import LeaseSearch from "../../Search/LeaseSearch";
import { capitalizeFirstLetter } from "../../Modals/AssetDetails/Tabs/FormatDate";
import { CiFilter } from "react-icons/ci";
import { IoMdAdd } from "react-icons/io";
import { CiViewTable } from "react-icons/ci";
import { IoIosClose } from "react-icons/io";
import NewAssetBtn from "../../Buttons/NewAssetBtn";
import { BiSortAlt2 } from "react-icons/bi";

function LeaseTable({ tabNo, setTabNo, leaseId, setLeaseId }) {
  // const navigate = useNavigate();
  // set the item quantity to load page numbers
  // const [items, setItems] = useState(0);
  // set items per page

  const [leaseDetails, setLeaseDetails] = useState([]);
  const [loader, setLoader] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [itemOffset] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  //set the state of filter
  const [filter, setFilter] = useState("rso_ids");
  //setting state for search suggestions and page count for pagination
  const [searchSuggetions, setSearchSuggetions] = useState({});
  const [searchType, setSearchType] = useState("rso-id");
  const [searchTypeArray, setSearchTypeArray] = useState([]);
  const [showSearchDiv, setShowSearchDiv] = useState([]);

  const getSearchData = async () => {
    try {
      const { data } = await axios.get(`${setBaseUrl}/lease/search`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
      });
     
      setSearchSuggetions(await data);
     
    } catch (error) {
      console.error(error);
    }
  };

  const getAllLease = async (searchId = "") => {
    // console.log(searchId);
    // console.log(itemOffset);
    try {
      setLoader(true);
      const headersobj = {
        "Content-Type": "application/json",
        "x-access-tokens": sessionStorage.getItem("token"),
        offset: itemOffset,
        // limit: itemsPerPage,
      };
      // console.log(searchType);
      if ((searchType !== "") | undefined && (searchId !== "") | null) {
        if (searchType === "schedule_date") {
          headersobj["start-date"] = startDate;
          headersobj["end-date"] = endDate;
        } else headersobj[`${searchType}`] = searchId;
      }
      // console.log(headersobj);
      const { data } = await axios.get(`${setBaseUrl}/lease/get_all`, {
        headers: headersobj,
      });
      // console.log(data);
      setLeaseDetails(data);
    } catch (error) {
      // console.error(error);
      alert(error.response.data.error);
    } finally {
      setShowSearchDiv([]);
      setLoader(false);
    }
  };

  // console.log(searchSuggetions.rso_ids)
  const handleSearchCategory = () => {
    switch (filter) {
      // case "all":
      //   getAllLease();
      //   break;
      // case "scheduled_date":
      //   // console.log("Scheduled date");
      //   setSearchType("schedule_date");
      //   setSearchTypeArray([...searchSuggetions?.odoo_order_id]);
      //   break;
      case "assetnumber":
        setSearchType("asset-id");
        setSearchTypeArray([...searchSuggetions.asset_no]);
        // console.log([...searchSuggetions.asset_no])
        break;
      case "rso_ids":
        setSearchType("rso-id");

        // console.log(searchSuggetions);
        setSearchTypeArray(searchSuggetions?.rso_ids);

        break;
      // case "lease":
      // console.log("lease");
      //   setSearchType("lease-id");
      //   setSearchTypeArray([...searchSuggetions.lease_no]);
      //   break;
      default:
        break;
    }
  };

  useEffect(() => {
    handleSearchCategory();
  }, [searchSuggetions]);

  function search(query) {
    // console.log("once");
    if (query == "") {
      setShowSearchDiv([]);
      return;
    }
    // console.log(searchTypeArray);
    if (searchTypeArray) {
      const filteredData = searchTypeArray?.filter((item) => {
        // console.log(item)
        if (!item?.no && item.toLowerCase().includes(query.toLowerCase()))
          return item;
        if (item?.no && item.no.toLowerCase().includes(query.toLowerCase()))
          return item;
        // if (item ) return item;
      });
      if (filteredData.length > 0) {
        // console.log(filteredData);
        setShowSearchDiv(filteredData);
      } else {
        setShowSearchDiv(["no results"]);
      }
    }
    // console.log(filteredData);
  }

  const handleEndDate = (e) => {
    let end_date = e.target.value;
    if (end_date < startDate) {
      alert("End date should be older than start date");
      return;
    }
    setEndDate(end_date);
  };
  //on search input onChange event
  const handleSearch = (e) => {
    search(e.target.value);
    // console.log(searchTypeArray);
    setSearchValue(e.target.value);
  };
  //clicking on show search Div
  const handleSearchClick = (id) => {
    // console.log(id);
    // setGetSearchList((prev) => !prev);
    getAllLease(id);
  };
  useEffect(() => {
    handleSearchCategory();
  }, [filter]);
  useEffect(() => {
    getSearchData();
    getAllLease();
    // console.log(leaseDetails);
  }, [tabNo, deleted, itemOffset]);

  // Filter Data
  let selected = ["all Lease", "active", "inactive", "expiring", "expired"];
  const [selectedItem, setSelectedItem] = useState("all Lease");
  const [openFilter, setOpenFilter] = useState(false);
  const [openTable, setopenTable] = useState(false);
  const [openFilterStatus, setFilterStatus] = useState(false);
  const [openYom, setOpenYom] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  let tableArray = [10, 50, 100, 150];
  const [selectTableData, setSelectTableData] = useState(null);
  const [ascending, setAscending] = useState(null);
  const [descending, setDescending] = useState(null);
  const [open, setOpen] = useState(false)
  const [openSearch, setOpenSearch] = useState(false)


//  // header of the table
  const sortHeader = [
    "Asset No",
    "Order Odoo Id",
    "Customer",
    "Total Amount",
    "Start Date",
    "End Date",
    "Status",
  ];
  
  const [openSort, setOpenSort] = useState(false);
  // sort names are storing
  const [sort, setSort] = useState(null);
  // for input in sorting
  const [inputSearch, setInputSearch] = useState("");

  // sorting the data in ascending order using the whole filter
  const [sortOrder, setSortOrder] = useState("asc");

  // Filtered header based on search input
  const filteredHeaders = sortHeader.filter((header) =>
    header.toLowerCase().includes(inputSearch.toLowerCase())
  );


  return (
    <>
      <div className="flex justify-between items-center border-b pb-2">
        <div className="flex gap-4 space-x-2 text-xs z-20">
          <button
            className="border rounded-lg h-6 w-16 justify-center  items-center flex gap-1"
            onMouseEnter={() => setopenTable(true)}
          >
            <CiViewTable />
            table
          </button>
          {selectTableData && (
            <>
              <button className="bg-[#EBEBEB] px-2 flex items-center rounded">
                {selectTableData}
                <IoIosClose
                  className="h-4 w-4"
                  onClick={() => setSelectTableData(null)}
                />
              </button>
            </>
          )}


          {ascending && (
            <>
              <button className="bg-[#EBEBEB] px-2 flex items-center rounded">
                {ascending}
                <IoIosClose
                  className="h-4 w-4"
                  onClick={() => setAscending(null)}
                />
              </button>
            </>
          )}

          {descending && (
            <>
              <button className="bg-[#EBEBEB] px-2 flex items-center rounded">
                {descending}
                <IoIosClose
                  className="h-4 w-4"
                  onClick={() => setDescending(null)}
                />
              </button>
            </>
          )}
          {selectedItem !== "all Lease" && (
            <>
              <button className="bg-[#EBEBEB] px-2 flex items-center rounded">
                {selectedItem}
                <IoIosClose
                  className="h-4 w-4"
                  onClick={() => setSelectedItem("all Lease")}
                />
              </button>
            </>
          )}

          {openTable && (
            <>
              <div
                onMouseLeave={() => setopenTable(false)}
                className="absolute mt-10 z-[9999] left-0 text-xs
                flex flex-col justify-center 
                bg-white dark:bg-[#070e18] w-[80px] shadow-2xl rounded-lg h-fit items-center"
              >
                {tableArray.map((table) => (
                  <>
                    {" "}
                    <button
                      key={table}
                      className="hover:bg-[#EBEBEB] w-full  py-1 dark:hover:text-black "
                      onClick={() => setSelectTableData(table)}
                    >
                      {table}
                    </button>{" "}
                  </>
                ))}
              </div>
            </>
          )}

          {openFilter && (
            <>
              <div
                className="absolute mt-0 z-[9999] right-[17%]
                flex flex-col justify-center  pt-6 shadow-2xl
                bg-white dark:bg-[#070e18]  w-[150px]  rounded-lg h-fit items-center"
              >
                <button
                  className="hover:bg-[#EBEBEB] w-full dark:hover:text-black py-2"
                  onMouseEnter={() => setFilterStatus(true)}
                >
                  Status
                </button>
                {/* <button
                  className="hover:bg-[#EBEBEB] w-full py-2 dark:hover:text-black"
                  onMouseOver={() => setOpenYom(true)}
                >
                  YOM
                </button>
                <button
                  className="hover:bg-[#EBEBEB] w-full py-2 dark:hover:text-black"
                  onMouseOver={() => setOpenCategory(true)}
                >
                  Category
                </button>
                <button className="hover:bg-[#EBEBEB] w-full py-2 dark:hover:text-black"  onClick={() => {setAscending('ascending')}} >Ascending</button>
                <button className="hover:bg-[#EBEBEB] w-full py-2 dark:hover:text-black"  onClick={() => {setDescending('descending')}} >Descending</button>
                */}
                <button
                  className="flex items-center justify-start py-2
      "
                >
                  <IoMdAdd />
                  filter
                </button>
              </div>

              {openFilterStatus && (
                <>
                  <div
                    onMouseLeave={() => setFilterStatus(false)}
                    className="absolute mt-9 z-[9999] right-[30%]
                flex flex-col justify-center  pt-6 shadow-2xl
                bg-white dark:bg-[#070e18] dark:border-none dark:shadow-md   dark:shadow-[#767676] w-[150px] border rounded-lg h-fit items-center"
                  >
                    {selected.map((select) => {
                      return (
                        <>
                          <button
                            className={`hover:bg-[#EBEBEB] dark:hover:text-black w-full  py-2 ${
                              selectedItem === select
                                ? "bg-[#b9b8b8] dark:text-black"
                                : ""
                            }  `}
                            onClick={() => setSelectedItem(select)}
                            key={select}
                          >
                            {capitalizeFirstLetter(select)}
                          </button>
                        </>
                      );
                    })}

                    <button className="flex items-center justify-start py-2">
                      <IoMdAdd />
                      Status
                    </button>
                  </div>
                </>
              )}

              <div
                className="fixed top-0 left-0 bottom-0 right-0 bg-[#00000006] z-50 "
                onClick={() => setOpenFilter(false)}
              />
            </>
          )}
        </div>
        {/*  */}

        {/*  */}
        <div className=" flex justify-center items-center ml-auto mt-0">
          
        <div
            className={`hover:bg-[#EEE] rounded-md p-1.5 borde text-[#959595] justify-center  items-center flex gap-1 cursor-pointer ${
              openFilter && "bg-[#EEE]"
            }`}
            onClick={() => setOpenFilter(true)}
          >
            <CiFilter />
            
          </div>
           {/* Sorted Data */}
           {sort && (
            <>
          
       
              <button className="text-xs bg-[#EEE] rounded-md px-1 py-1 flex items-center justify-center">
                {sort}
                <IoIosClose
                  className="w-4 h-4 cursor-pointer"
                  onClick={() => setSort(null)}
                />
              </button>
            </>
          )}

          {/* Sorting Suggestions */}
          <button
            className="p-1.5 rounded-md hover:bg-[#EEE] borde"
            onClick={() => setOpenSort(!openSort)}
          >
            <BiSortAlt2 className="h-4 w-4 rounded  text-[#959595]" />
          </button>
          {openSort && (
            <>
              <div className="absolute text-xs w-[200px] h-fit z-30 top-12 right-[10%] rounded-lg p-2 border bg-white">
                <input
                  type="text"
                  className="bg-transparent w-[180px] h-7 rounded-md text-xs"
                  placeholder="Search..."
                  onChange={(e) => setInputSearch(e.target.value)}
                  value={inputSearch}
                />
                <ul className="mt-2">
                  {filteredHeaders.length > 0 ? (
                    filteredHeaders.map((header) => (
                      <li
                        className="px-2 py-2 hover:bg-[#EEE] rounded-md cursor-pointer"
                        key={header}
                        onClick={() => {
                          setSort(header);
                          setOpenSort(false);
                        }}
                      >
                        {header}
                      </li>
                    ))
                  ) : (
                    <li className="px-2 py-2 text-gray-500">
                      No results found
                    </li>
                  )}
                </ul>
              </div>
              <div
                className={`fixed top-0 left-0 bottom-0 right-0 bg-[#00000006] z-10 ${
                  !openSort && "hidden"
                } `}
                onClick={() => {
                  setOpenSort(false);
                }}
              />
            </>
          )}
          {searchType !== "schedule_date" && (
            <div className="relative flex flex-col gap-1 z-[5000]">
              <LeaseSearch
                setFilter={setFilter}
                handleSearch={handleSearch}
                handleSearchClick={handleSearchClick}
                searchValue={searchValue}
                open={open}
                setOpen={setOpen}
                setOpenSearch={setOpenSearch}
              />


              {searchType === "rso-id" ? (
                <>
                  {openSearch && showSearchDiv.length > 0 && (
                    <div className="absolute z-[9999] bg-white p-2 border h-[50vh]  shadow-xl rounded-[9px] w-[200px] hide-scrollbar mt-10 overflow-y-scroll right-4">
                      {showSearchDiv.map((item) => (
                        <p
                          onClick={() => handleSearchClick(item)}
                          className="cursor-pointer hover:bg-[#EEE] text-xs border-b rounded-md p-2"
                        >
                          {item !== undefined ? `${item}` : "No Results"}
                        </p>
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {openSearch && showSearchDiv.length > 0 && (
                    <div className="absolute z-[9999] bg-white p-2 border h-[50vh]  shadow-xl rounded-[9px] w-[200px] hide-scrollbar mt-10 overflow-y-scroll right-4">
                      {showSearchDiv.map((item) => (
                        <p
                          onClick={() => handleSearchClick(item?.id)}
                          className="cursor-pointer hover:bg-[#EEE] text-xs border-b rounded-md p-2"
                        >
                          {item?.no !== undefined ? `${item.no}` : "No Results"}
                        </p>
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
          )}


          {searchType === "schedule_date" && (
            <>
              <input
                type="date"
                id="datesmaintenance"
                name="dates"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="rounded-lg bg-slate-100 border-slate-400 text-sm text-slate-700"
              />
              <span className="mx-4"> to </span>
              <input
                type="date"
                id="datesmaintenance"
                name="dates"
                value={endDate}
                onChange={handleEndDate}
                className="rounded-lg bg-slate-100 border-slate-400 text-sm text-slate-700"
              />
              <button
                onClick={handleSearchClick}
                className="bg-[#1920d5] p-2 mx-4 rounded-lg text-white"
              >
                Search
              </button>
            </>
          )}

            <div className={`fixed top-0 left-0 bottom-0 right-0 bg-[#00000006] z-10 ${!open && 'hidden'} `}
              onClick={() => {setOpen(false); setOpenSearch(false)}}/>
 <NewAssetBtn tabName="lease" /> 
        </div>
      </div>
      {loader ? (
        <Loader />
      ) : (
        <CurrentItems
          setTabNo={setTabNo}
          leaseDetails={leaseDetails}
          leaseId={leaseId}
          setLeaseId={setLeaseId}
          loader={loader}
          setLoader={setLoader}
          deleted={deleted}
          setDeleted={setDeleted}
          selected={selected}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          selectTableData={selectTableData}
          sortHeader={sortHeader}
          sort={sort}
          setSort={setSort}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
        />
      )}
    </>
  );
}

export default LeaseTable;
