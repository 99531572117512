import React, { useEffect, useState } from 'react'
import { setBaseUrl } from '../../../config'
import axios from 'axios'
import { formatDate } from './Tabs/FormatDate'

const TimeLineDuration = ({devId, setShowMachine}) => {
  const [timeData, setTimeData] = useState(null)
  console.log(devId)  

  const fetchTimeLineData = async () => {
      try {
          const response = await axios.get(`${setBaseUrl}/asset/get-device-timeline`, 
               {
                  headers: {
                      "x-access-tokens": sessionStorage.getItem("token"),
                      'device-id': devId,
                  }
              }
          )

          if(!response.status === 200) {
              throw new Error('Network response was not ok')
          }
          const items = response.data
          setTimeData(items)
          console.log(timeData)
          console.log(response.data)
      } catch (error) {
          console.error(error)
      }
  }

  useEffect(() => {    
      fetchTimeLineData()
  }, [])

  return (
    <div className=''>
         <div className=" w-full  mt-1 mb-12">
          
            <h1 className="text-xs font-bold py-2 mx-2 flex justify-between">Device Timeline Details

              <button className="bg-black text-white h-7 w-[80px] rounded-full" onClick={() => {setShowMachine(false)}}>Close</button>
            </h1>
            <div className="border rounded-2xl">
          
            {timeData ?     
                <>
               <table className='text-center text-xs border-separate border-spacing-y-0   w-full relative'>
                <thead className='h-10 sticky  bg-[#efefef] dark:bg-gray-700 '>
                    <tr className="">
                        <td className='rounded-tl-2xl'>Start Date</td>
                        <td>Start Time</td>
                        <td>End Time</td>
                        <td className='rounded-tr-2xl'>Duration</td>
                    </tr>
                </thead>
                <tbody className="">
                    
                    {timeData && Object.keys(timeData).reverse().map((date) => (
                         timeData[date]?.reverse().map((item, index) => 
                         {   
                            let start = item[1].start_time.split(" ")[1]
                            let end = item[1].end_time.split(" ")[1]


                            const startTime = start.split(":")
                            const endTime = end.split(":")
                            const durationTime = item[0].split(":")

                            const formattedStart = `${startTime[0]}:${startTime[1]}`
                            const formattedEnd = `${endTime[0]}:${endTime[1]}`
                              const formattedDuration = `${durationTime[0]}:${durationTime[1]}:${durationTime[2].split('.')[0]}`

                            return (
                            <tr key={`${date}-${index}`} className='h-8 items-center '>
                                <td className="border-b">
                                    {formatDate(date)}
                                </td>
                             
                                <td className="border-b">
                                    {formattedStart}
                                </td>
                                <td className="border-b">
                                    {formattedEnd}
                                </td>
                                <td className="border-b">
                                    {formattedDuration}
                                </td>
                            </tr>
                        )
                    }
                    )
                    ))}
                </tbody>
               </table>
                </> 
                :
                 <>
                 <div className="text-center mt-10 text-xs">Data is not generated!!</div>
                 </>
            }


            </div>
        </div>
    </div>
  )
}

export default TimeLineDuration
