import React, { useEffect, useState } from "react";
import MISReportBtn from "../../Buttons/MISReportBtn";
import ExcelReportBtn from "../../Buttons/ExcelReportBtn";
import { useNavigate } from "react-router-dom";
import NewAssetBtn from "../../Buttons/NewAssetBtn";
import { setBaseUrl } from "../../../config";
import axios from "axios";
import OperatorSearch from "../../Search/OperatorSearch";

function UpperTab({  getAllOperators,  }) {

  const [searchSuggetions, setSearchSuggetions] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [searchTypeArray, setSearchTypeArray] = useState([]);
  const [showSearchDiv, setShowSearchDiv] = useState([]);
  const [searchType, setSearchType] = useState("");
  const [open, setOpen] = useState(false)
  const handleSearchCategory = (value) => {
    // console.log(value);
    switch (value) {
    
      case "adhaarNo":
        setSearchType("aadhar-no");
        let adNos = Object.keys(searchSuggetions.aadhar);
        setSearchTypeArray(adNos);
        break;
      case "pf":
        setSearchType("pf-account");
        let pfAcNo = Object.keys(searchSuggetions.pf_account_no);
        setSearchTypeArray(pfAcNo);
        break;
      case "name":
        setSearchType("name");
        let opName = Object.keys(searchSuggetions.name);
        setSearchTypeArray(opName);
      default:
        break;
    }
  };
  const getSearchData = async () => {
    try {
      const { data } = await axios.get(`${setBaseUrl}/operator/search`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
      });
      // console.log(data);
      setSearchSuggetions(data);
      // setItems(data.asset_data.length());
    } catch (error) {
      // console.error(error);
    }
  };

  useEffect(() => {
    getSearchData();
  }, []);

  function search(query) {
    query = query.trim();
    if (query === "") {
      setShowSearchDiv([]);
      return;
    }
    const filteredData = searchTypeArray.filter((item) =>
      item.toLowerCase().includes(query.toLowerCase())
    );
    if (filteredData.length > 0) {
      setShowSearchDiv(filteredData);
    } else {
      setShowSearchDiv(["no results"]);
    }

    // console.log(filteredData);
  }
  const handleSearch = (e) => {
    search(e.target.value);
    setSearchValue(e.target.value);
  };
  const handleSearchClicik = (item) => {
    // console.log(searchType, item);
    getAllOperators(searchType, item);
    setShowSearchDiv([]);
  };

  const [openSearch, setOpenSearch] = useState(false)

  return (
    <div class="flex justify-between flex-col md:flex-row items-center border-b pb-2 py-0">
      <p class="inline font-bold text-xs  text-slate-700 dark:text-slate-200 z-20">
        Operator
      </p>





      <div className="flex flex-col items-center md:flex-row">
        <div className="flex flex-col md:flex-row"/>
        <div className="z-[5000]">
        <OperatorSearch handleSearchCategory={handleSearchCategory} searchValue={searchValue} handleSearch={handleSearch} handleSearchClicik={handleSearchClicik}
         open={open}
         setOpen={setOpen}
         setOpenSearch={setOpenSearch} 
        />

          {openSearch && showSearchDiv.length > 0 && (
            <div className="absolute z-[9999] bg-white p-2 border h-[50vh]  shadow-xl rounded-[9px] w-[200px] hide-scrollbar mt-6 overflow-y-scroll right-4">
              {showSearchDiv.map((item) => (
                <p
                  onClick={() => handleSearchClicik(item)}
                  className="cursor-pointer hover:bg-[#EEE] text-xs border-b rounded-md p-2"
                >
                  {item}
                </p>
              ))}
            </div>
          )}
        </div>
        <div className={`fixed top-0 left-0 bottom-0 right-0 bg-[#00000006] z-10 ${!open && 'hidden'} `}
              onClick={() => {setOpen(false); setOpenSearch(false)}}/>
                <NewAssetBtn tabName= 'operator'/>
      </div>
    </div>
  );
}

export default UpperTab;
