import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { setBaseUrl } from "../../../config";
import { LoaderContext } from "../../../Context/LoaderContext";
import Loader from "../../Loader";
import AssetDetailsTab from "./AssetDetailsTab";
import MaintenanceTab from "../AssetDetails/MaintenanceTab";
import { Link, useNavigate, useParams } from "react-router-dom";
import downloader from "../../../assets/images/icons8-download-48.png";
import NewAssetBtn from "../../Buttons/NewAssetBtn";
import { PermissionContext } from "../../../Context/PermissionsContext";

import Image from "../../viewer/Image";
import Documents from "../../InnerTabs/Documents";
import { createPortal } from "react-dom";
import {
  log_sheet_entry_with_action,
  log_sheet_entry_without_action,
} from "../../../apis/LeaseApis";
import LogTable from "./LogTable";
import LeaseInfo from "./LeaseInfo/LeaseInfo";
import LeaseDashboard from "./LeaseInfo/LeaseDashboard";

function LeaseDetails() {
  const [perms] = useContext(PermissionContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const [leaseSpecificDetails, setLeaseSpecificDetails] = useState({});
  const [leaseInAsset, setLeaseInAsset] = useState("");
  const [innerTab, setInnerTab] = useState(1);
  const [imageUrl, setImageUrl] = useState([]);
  const [documentUrl, setDocumentUrl] = useState([]);
  const [invoice, setInvoice] = useState([]);
  const [loader, setLoader] = useContext(LoaderContext);
  const get_lease_details = async () => {
    try {
      setLoader(true);
      const { data } = await axios.get(`${setBaseUrl}/lease/get_data_by_id`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          id: id,
        },
      });
      console.log(data);
      setLeaseInAsset(data.asset_id);
      setLeaseSpecificDetails(data);

      setImageUrl(data?.photos);

      setDocumentUrl(data?.attachments);
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const get_invoice = async () => {
    try {
      const { data } = await axios.get(`${setBaseUrl}/lease/get-invoice`, {
        headers: {
          "x-access-tokens": sessionStorage.getItem("token"),
          "lease-id": id,
        },
      });
      console.log(data);
      setInvoice(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    get_lease_details();
    get_invoice();
  }, []);

  const [log_file, setLog_file] = useState(null);
  const log_sheet_ref = useRef(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [conflict, setConflict] = useState(false);
  const [log_Data, setLog_Data] = useState(null);
  const [sendingLog, setSendingLog] = useState(false);
  const [reload, setReload] = useState(false);
  const [runDuration, setRunDuration] = useState(null)
  // const [headerDataOdoo, setHeaderDataOdoo] = useState({asset_no:'',custormer:null, model: null, })
  const upload_log_sheet_click = () => {
    log_sheet_ref.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files[0];
    setLog_file(selectedFiles);
    setOpenPopup(true);
  };
  const submit_file = async () => {
    try {
      setSendingLog(true);
      const { data } = await log_sheet_entry_without_action(log_file);
      if (data.type == "conflict") {
        setSendingLog(false);
        setConflict(true);
        // let log_data = JSON.stringify(data.data);
        setLog_Data(data.data);
      }
      if (data.type == "success") {
        setSendingLog(false);
        alert("Success");
      }
    } catch (error) {
      setSendingLog(false);
      alert(error.response.data.error);
    }
  };
  const submit_after_conflict = async (value) => {
    try {
      const { data } = await log_sheet_entry_with_action(log_file, value);

      setOpenPopup(false);
      alert("File updated successfully");
    } catch (error) {
      setOpenPopup(false);
      alert(error.response.data.error);
    }
  };

  const cancel_all = () => {
    window.location.reload();
  };

  if (loader) {
    return <Loader />;
  } else {
    return (
      <>
      <div id="lease-details" className="dark:text-white mb-16">
       
{/* Header */}
<div className="flex justify-between px-2">
    <div className="flex items-center justify-between space-x-0 fixed bottom-0 left-0 h-12 bg-white border  right-0">
      <button
       onClick={() => navigate(-1)}
       className="text-xs text-black border rounded-full w-[65px] h-6 ml-16 "
      >Cancel</button>
     
       <div>

    <div className="flex items-center justify-between space-x-4 mr-6">
    
             {(perms.indexOf("ADMIN.ALL") !== -1 ||
              perms.indexOf("ASSETS.ALL") !== -1 ||
              perms.indexOf("ASSETS.CRU") !== -1) && (
                <>
                <button  onClick={() =>  navigate("/lease/leaseUpdate/" + id)} className="bg-black text-white w-[65px] h-6 text-xs  rounded-full">Update</button>
                </>

                )}
                <button className="text-xs border rounded-full w-[65px] h-6 "
                 onClick={() => setReload((prev) => !prev)}
                >Reload</button>
              
    </div>
       </div>

    </div>
  </div>

        <div className="">
      
      <LeaseInfo leaseSpecificDetails={leaseSpecificDetails} asset_id={leaseInAsset} leaseId={id} runDuration={runDuration}/>

          {/* Navigation tab */}
          <div className="nav-tabs text-xs px-6 relative flex flex-col sm:flex-row justify-center lg:justify-start ">
            <span
              onClick={() => setInnerTab(1)}
              className={`lease-details-tab py-4 sm:mr-8 cursor-pointer ${
                innerTab === 1 && "assetDetailsActive"
              } `}
            >
              Dashboard
            </span>
           
            <span
              onClick={() => setInnerTab(3)}
              className={`lease-details-tab py-4 sm:mr-8 cursor-pointer ${
                innerTab === 3 && "assetDetailsActive"
              } `}
            >
              Maintenance
            </span>
           
            <span
              onClick={() => setInnerTab(5)}
              className={`asset-details-tab py-4 sm:mr-8 cursor-pointer ${
                innerTab === 5 ? "assetDetailsActive" : ""
              }`}
            >
              Documents
            </span>
           
            <div className="flex justify-center items-center flex-grow-[1]"></div>
            {/* <button
              onClick={upload_log_sheet_click}
              className="absolute right-0 self-end bg-[#d04848] flex-grow-[1] p-2 text-white text-[1.2rem] font-semibold rounded-md"
            >
              Upload Log Sheet
            </button> */}
            <input
              type="file"
              accept=".xls, .xlsx"
              name="excelFile"
              className="hidden"
              ref={log_sheet_ref}
              onChange={handleFileChange}
            />
            {openPopup &&
              createPortal(
                <div className="fixed top-0 lef-0 bottom-0 w-full h-[100vh] z-[999] bg-cover bg-center backdrop-blur-md">
                  <div className="flex  justify-center items-center gap-6 h-full">
                    <span
                      className="absolute top-[3rem] text-[1.8rem] font-bold text-[#c57f3a] cursor-pointer "
                      onClick={() => cancel_all()}
                    >
                      Cancel
                    </span>
                    <div className=" flex flex-col gap-6 p-10 text-[#fff]  font-bold border-[2px] border-[#3a2323] bg-slate-400">
                      <p className="text-[1.6rem]">log file:</p>
                      <p className="text-[1.6rem]">
                        {log_file == null ? "File not choosen" : log_file.name}
                      </p>
                      {conflict && (
                        <div className="flex flex-col gap-4 overflow-scroll">
                          <p>Conflicts</p>
                          {log_Data.map((item) => (
                            <div className="bg-[#948d8d] p-2">
                              <div className="flex gap-2  text-white">
                                <p>Oddo Id:</p>
                                <p>{item.odoo_id}</p>
                              </div>
                              <div className="flex gap-2  text-white">
                                <p>Studio Operator:</p>
                                <p>{item.x_studio_operator1[1]}</p>
                              </div>
                              <div className="flex gap-2  text-white">
                                <p>X Studio Customer:</p>
                                <p>{item.x_studio_customer}</p>
                              </div>
                              <div className="flex gap-2  text-white">
                                <p>X Studio Date:</p>
                                <p>{item.x_studio_date}</p>
                              </div>
                              <div className="flex gap-2  text-white">
                                <p>X Studio Rental Register :</p>
                                <p>{item.x_studio_rental_register_no}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                      {conflict && <div></div>}
                      {conflict == false && (
                        <button
                          className="bg-[#981d1d] text-white p-4 rounded-lg"
                          onClick={submit_file}
                        >
                          {sendingLog ? "Sending" : "Submit"}
                        </button>
                      )}
                      {conflict && (
                        <div className="flex justify-between items-center gap-6">
                          <button
                            onClick={() => submit_after_conflict("replace")}
                            className="bg-[#a52020] text-white text-[1rem] p-2"
                          >
                            Replace
                          </button>
                          <button
                            onClick={() => submit_after_conflict("keep")}
                            className="bg-[#991b1b] text-white text-[1rem] p-2"
                          >
                            Keep
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>,
                document.body
              )}
          </div>
        </div>

        {/* Tabs */}
        {innerTab === 1 && (
          <>
       
          <LeaseDashboard leaseSpecificDetails={leaseSpecificDetails}/>
          </>

        )}

        {/* {innerTab === 2 && (
          <div
            id="opD-assets"
            className="leaseD-tabs  mt-4 animSlideUp relative"
          >
            <div className="text-right">
              <NewAssetBtn tabName="asset" />
            </div>
            <AssetDetailsTab asset_id={leaseInAsset} leaseId={id} />
          </div>
        )} */}
        {innerTab === 3 && (
          <div
            id="opD-operators"
            className="leaseD-tabs  mt-4 animSlideUp relative"
          >
            <div className="text-right">
              <NewAssetBtn tabName="maintenance" />
            </div>
            <MaintenanceTab lease_id={id} />
          </div>
        )}
      
        {innerTab === 5 && (
          <>
          <Documents documentUrl={documentUrl} section="lease" />
          <div className="text-xs">

          <div className="assetD-tabs text-xs mt-4">
            <div className="grid grid-cols-12 gap-6">
              <div className="intro-y box col-span-12 bg-white dark:bg-slate-700 ">
                <table className="w-full text-center  border-separate border-spacing-6 border rounded-2xl ">
                  {/* <>
                    <tr className="font-normal text-xs">
                      <th>Invoice Name</th>
                      <th>Invoice Number</th>
                      <th>Invoice Date</th>
                      <th>Operator Name</th>
                      <th>Document</th>
                    </tr>
                  </> */}
                  <tbody>
                    {invoice.map((item) => (
                      <tr>
                        <td>{item.invoice_name}</td>
                        <td>{item.invoice_no}</td>
                        <td>{item.invoice_date}</td>
                        <td>{item.operator_name}</td>
                        <td>
                          <div className="flex justify-center items-center">
                            <p>
                              {
                                item.documents_link.split(
                                  "test-2023-durbin.s3.amazonaws.com/invoice/invoice"
                                )[1]
                              }
                            </p>
                            <a
                              href={`https://${item?.documents_link}`}
                              target="_blank"
                              className="self-end"
                            >
                              <img
                                src={downloader}
                                alt="Download"
                                style={{
                                  width: "35px",
                                  height: "35px",
                                  margin: "0.30rem",
                                }}
                              />
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          </div>
          </>
        )}
       

        {/* <LogTable  /> */}
        {leaseSpecificDetails?.odoo_order_id  ? <LogTable rso_id={leaseSpecificDetails.odoo_order_id} setRunDuration={setRunDuration}/> : null}
      </div>
      </>
    );
  }
}

export default LeaseDetails;
