import React from 'react'
import { currency, formatDate, FormPercentage } from './FormatDate';
import AssetDashboards from './AssetDashboards';

const AssetCommercial = ({commercialDetails, epocToHumanDate}) => {
    

    

    let TotalLandedCost = parseFloat(commercialDetails[0]?.total_landed_cost).toFixed(2)
    let TotalLandedCostWithGST = parseFloat(commercialDetails[0]?.total_landed_cost_with_gst).toFixed(2)
    let BOENum = parseFloat(commercialDetails[0]?.boe_no).toFixed(0)
    // let cutsomDutyPercentage = parseFloat(/commercialDetails[0]?.amount_rem_to_oem).toFixed(2)
      // console.log([0])
    
  // let insurance = formatDate(`${}`)
  let insurance = formatDate(parseInt(commercialDetails[0]?.insurance_renewal))
  return (

  <>
   <div className="flex flex-col border text-xs w-1/2  rounded-2xl justify-between px-5 pb-5 py-2  dark:bg-slate-900 bg-white ">
        {/* Frame 1 */}
        <h1 className="text-[#7e7e7e]  text-base font-bold px-1 ">Asset Commercial Details</h1>
          <div className="py-2 flex justify-between border px-2 h-10 items-center rounded-t-xl hover:bg-[#EEEE] cursor-pointer">
            <span className="text-gray-500 ">Purchase Order No.</span>
            <span className="block font-semibold text-xs">
            {commercialDetails[0]?.purchase_order_no}
            </span>
          </div>
          <div className="py-2  flex justify-between  border-b border-l border-r px-2 gap-2 hover:bg-[#EEEE] cursor-pointer">
            <span className="text-gray-500">Purchase Order Date</span>
            <span className="block font-semibold text-xs">
            {formatDate(commercialDetails[0]?.purchase_order_date)}
            </span>
          </div>
          <div className="py-2  flex justify-between border-b border-l border-r px-2  gap-2 hover:bg-[#EEEE] cursor-pointer">
            <span className="text-gray-500">Insurance Renewal:</span>
            <span className={`block font-semibold text-xs
             
             ${new Date(insurance) < new Date() 
              ? 
              'text-red-700' : 'text-blue-700'}
              `}>
                {/* {console.log(insurance)} */}
                {!insurance === "nill" ? `${insurance}` : "--"}
                 
            </span>
          </div>

    

        {/* Frame 2 */}
          <div className="py-2  flex justify-between border-b border-l border-r px-2  gap-2 hover:bg-[#EEEE] cursor-pointer">
            <span className="text-gray-500">CIF Charges:</span>
            <span className="block font-semibold text-xs">
            ${currency(parseFloat(commercialDetails[0]?.cif_charges).toFixed(2))}
            </span>
          </div>
          <div className="py-2  flex justify-between border-b border-r border-l px-2 gap-2 hover:bg-[#EEEE] cursor-pointer">
            <span className="text-gray-500">Ex-Works Price:</span>
            <span className="block font-semibold text-xs">
            ${currency(parseFloat(commercialDetails[0]?.exworks_price).toFixed(2))}
            </span>
          </div>
          <div className="py-2  flex justify-between border-b border-r border-l px-2  gap-2 hover:bg-[#EEEE] cursor-pointer">
            <span className="text-gray-500">Total Cost (Ex-Works + CIF):</span>
            <span className="block font-semibold text-xs">
            ${currency(commercialDetails[0]?.total_cost)}
            </span>
          </div>

        {/* Frame 3 */}
          <div className="py-2  flex justify-between border-b border-r border-l px-2  gap-2 hover:bg-[#EEEE] cursor-pointer">
            <span className="text-gray-500">Exchange Rate <br />   (at Remittance):</span>
            <span className="block font-semibold text-xs">
            ₹{commercialDetails[0]?.exchange_rate_rem}
            </span>
          </div>
          <div className="py-2  flex justify-between border-b border-r border-l px-2  gap-2 hover:bg-[#EEEE] cursor-pointer">
            <span className="text-gray-500">Amount of Remitted OEM:</span>
            <span className="block font-semibold text-xs text-blue-600">
            ₹{currency(Math.floor(commercialDetails[0]?.amount_rem_to_oem))}
            </span>
          </div>
          <div className="py-2  flex justify-between border-b border-r border-l px-2  gap-2 hover:bg-[#EEEE] cursor-pointer">
            <span className="text-gray-500">Date of Remittance to OEM:</span>
            <span className="block font-semibold text-xs">
            {formatDate(commercialDetails[0]?.date_of_rem_to_oem)}
            </span>
          </div>


        {/* Frame 4 */}
        <div className="py-2  flex justify-between border-b border-r border-l px-2 gap-2 hover:bg-[#EEEE] cursor-pointer">
            <span className="text-gray-500">BOE No</span>
            <span className="block font-semibold text-xs">
            {BOENum}
            </span>
          </div>
          <div className="py-2  flex justify-between border-b border-r border-l px-2 gap-2 hover:bg-[#EEEE] cursor-pointer">
            <span className="text-gray-500">Exchange Rate (at BOE):</span>
            <span
              className={`block font-semibold text-xs ${
              ''
              }`}
            >
             ₹{commercialDetails[0]?.exrate_boe}
            </span>
          </div>
          <div className="py-2  flex justify-between border-b border-r border-l px-2  gap-2 hover:bg-[#EEEE] cursor-pointer">
            <span className="text-gray-500">Port of Clearance:</span>
            <span className="block font-semibold text-xs">
            {commercialDetails[0]?.port_of_clearance}
            </span>
          </div>

      {/* Frame 5 */}
          <div className="py-2  flex justify-between border-b border-r border-l px-2  gap-2 hover:bg-[#EEEE] cursor-pointer">
            <span className="text-gray-500">Custom Duty Value:</span>
            <span className="block font-semibold text-xs">
            ₹{currency(parseFloat(commercialDetails[0]?.custom_duty_value).toFixed(2))} 
            ({FormPercentage(commercialDetails[0]?.custom_duty_value,
               commercialDetails[0]?.amount_rem_to_oem)}%)
            </span>
          </div>
          <div className="py-2  flex justify-between border-b border-r border-l px-2  gap-2 hover:bg-[#EEEE] cursor-pointer">
            <span className="text-gray-500">Clearing Charges:</span>
            <span
              className={`block font-semibold text-xs ${
              ``
              }`}
            >
              ₹{currency(parseFloat(commercialDetails[0]?.clearing_charges).toFixed(2))} 
              ({FormPercentage(commercialDetails[0]?.clearing_charges,
                 commercialDetails[0]?.amount_rem_to_oem)}%)
            </span>
          </div>
          <div className="py-2  flex justify-between border-b border-r border-l px-2 gap-2 hover:bg-[#EEEE] cursor-pointer">
            <span className="text-gray-500">CHA Charge:</span>
            <span className="block font-semibold text-xs">
            ₹{currency(parseFloat(commercialDetails[0]?.cha_charges).toFixed(2))}
            ({FormPercentage(commercialDetails[0]?.cha_charges,
              commercialDetails[0]?.amount_rem_to_oem)}%)
            </span>
          </div>

        {/* Frame 6 */}
       
          <div className="py-2  flex justify-between border-b border-r border-l px-2 gap-2 hover:bg-[#EEEE] cursor-pointer">
            <span className="text-gray-500">GST Amount:</span>
            <span className="block font-semibold text-xs ">
            ₹{currency(parseFloat(commercialDetails[0]?.gst_amount).toFixed(2))}
            </span>
          </div>
          <div className="py-2  flex justify-between border-b border-r border-l px-2 gap-2 hover:bg-[#EEEE] cursor-pointer">
            <span className="text-gray-500">Total Landed Cost:</span>
            <span
              className={`block font-semibold text-xs ${
              ``
              }`}
            >
              ₹{currency(TotalLandedCost)}
            </span>
          </div>
          <div className="py-2  flex justify-between border-b border-r border-l px-2 gap-2 hover:bg-[#EEEE] cursor-pointer">
            <span className="text-gray-500">Total Landed Cost with GST:</span>
            <span className="block font-semibold text-xs text-blue-600">
             ₹{currency(TotalLandedCostWithGST)}
            </span>
          </div>
     

        
          <div className="py-2  flex justify-between  border-b border-r border-l px-2 gap-2 hover:bg-[#EEEE] cursor-pointer">
            <span className="text-gray-500">Transportation Charges (INR):</span>
            <span
              className={`block font-semibold text-xs ${
              ``
              }`}
            >
              {!commercialDetails[0]?.transportation_charges === "nill" ? `₹${currency(parseFloat(commercialDetails[0]?.transportation_charges).toFixed(2))}`: `--`}
           
            </span>
          </div>
  
      {/* Frame 2 */}
          <div className="py-2  flex justify-between  border-b border-r border-l rounded-b-2xl px-2 gap-2 hover:bg-[#EEEE] cursor-pointer">
            <span className="text-gray-500">Created On:</span>
            <span className="block font-semibold text-xs">
            {epocToHumanDate !== "" ? formatDate(Date(epocToHumanDate.toString())) : ""}
            </span>
          </div>
         
      </div>

      {/* Part 2 */}
      <AssetDashboards commercialDetails={commercialDetails[0]} />
  </>
 

  )
}

export default AssetCommercial
