import React, { useContext, useEffect, useState } from "react";
import Navbar from "./navbar/Navbar";
import { Link, useLocation } from "react-router-dom";
import { NavbarContext } from "../Context/NavbarContext";
import { PermissionContext } from "../Context/PermissionsContext";
import webSiteTheme from "../utils/Theme";
import useAuthorities from "../utils/useAuthorities";
import EngineeringIcon from '@mui/icons-material/Engineering';
import MobileNav from "./navbar/MobileNav";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';

import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import ManageHistoryIcon from '@mui/icons-material/ManageHistory';


import { GiHamburgerMenu } from "react-icons/gi";
import NewAssetBtn from "./Buttons/NewAssetBtn";
import HistoryBtn from "./Buttons/HistoryBtn";
import MISReportBtn from "./Buttons/MISReportBtn";
import ExcelReportBtn from "./Buttons/ExcelReportBtn";

function Layout({ pageNum, children,    showCurrentTab, setShowCurrentTab}) {
  // const navigate = useNavigate();
  const [theme, setTheme] = useState("");
  const [navState, setNavState] = useContext(NavbarContext);
  // const [perms, setPerms] = useContext(PermissionContext);
  const [perms, ] = useContext(PermissionContext);
  const { assetsTabPerm, maintenanceTabPerm, leasesTabPerm, operatorsTabPerm } =
    useAuthorities();


  useEffect(() => {
    webSiteTheme();
    setTheme(sessionStorage.getItem("theme"));
  }, [theme]);

  const onClickTabChange = (number) => {
    console.log(number);
    setNavState(number);
  };


  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };





  const [open, setOpen] = useState(false)

  // const NavData = ['Dashboard', 'Assets',  'Maintenance', 'Lease', 'Operators', 'Settings']
  const NavData = [
    {
      title: 'Dashboard',
      route: 'dashboard',
      imgUrl: HomeOutlinedIcon,
    },
    {
      title: 'Assets',
      route: 'assets',
      imgUrl: BusinessCenterOutlinedIcon,
    },
    {
      title: 'Maintenance',
      route: 'maintenance',
      imgUrl: ManageHistoryIcon,
    },
    {
      title: 'Lease',
      route: 'lease',
      imgUrl: FeedOutlinedIcon,
    },
    {
      title: 'Operators',
      route: 'operators',
      imgUrl: EngineeringIcon,
    },
    {
      title: 'Settings',
      route: 'settings',
      imgUrl: SettingsOutlinedIcon,
    },
  ]
  const path = useLocation().pathname;
const [hover, setHover] = useState(false)

  return (

    <>
     <Navbar theme={theme} setTheme={setTheme} setOpen={setOpen} open={open}/>
     <div className="flex ">
        <div
        onMouseOver={()=> setHover(true)}
        onMouseLeave={() => setHover(false)}
        className={` h-screen fixed ${hover? `w-[200px]`:`w-[60px]`} z-[9999] transition-all ease-in-out duration-300 bg-white border-r dark:border-none dark:bg-gray-950 text-black shadow-2xl`}>
          <div className=" flex flex-col gap-6  mt-16" >
              {NavData.map((nav, i) => {
                 const  isActive = path.split('/')[1] === `${nav.route}`;

                 return (
                  <>
                

                  <Link 
                    to={`/${nav.route}`}
                    key={i}
                    className={` flex flex-rowb p-2  mx-4  items-center 
                      ${hover? `  rounded-full `:` items-center justify-center rounded-full` }   
                      ${isActive? 'bg-[#dddcdc] text-black ': 'bg-transparent text-black dark:text-white '}`}
                    >
                    <nav.imgUrl className=" rounded-full fixed mx-1 h-6 w-6  p-0.5" />
                    <h1 className={`text-xs  ${hover? 'opacity-100  ml-10 transition-all duration-500  ease-in-out':'opacity-0'}`}>{nav.title}</h1>
                  </Link>

                  </>
                 )
              })}
          </div>
        </div>

        <div className=" relative flex flex-col ml-[60px]  mt-[35px] h-full   w-full  ">
                   
                    {open && (
                      <>
                      <div className="bg-white dark:bg-gray-200 text-black w-[150px] h-[200px] rounded-xl absolute top-2 border right-2 z-[9999] shadow-2xl    p-2 space-y-2"  onClick={()=> setOpen(false)}>
                      {/* <NewAssetBtn tabName={buttonName} ///> */}
                          {/* {navState === 2 ? 
                                  <NewAssetBtn tabName='asset' />
                                  : 
                            navState === 4 ?   
                                  <NewAssetBtn tabName="lease" /> 
                                  : 
                            navState === 6 ? 
                                  <NewAssetBtn tabName= 'operator'/>
                                  :
                            navState === 3? 
                                  <NewAssetBtn tabName='maintenance' /> 
                                  :
                                  ""} */}

                      <HistoryBtn />
                      {/* <MISReportBtn /> */}

                            {navState === 2 ? 
                                  <ExcelReportBtn tabName='assets' />
                                  : 
                            navState === 4 ?   
                                  <ExcelReportBtn tabName="lease" /> 
                                  : 
                            navState === 6 ? 
                                  <ExcelReportBtn tabName= 'operator'/>
                                  :
                            navState === 3? 
                                  <ExcelReportBtn tabName='maintenance' /> 
                                  :
                                  <ExcelReportBtn />}

                      </div>
                      <div className="fixed top-0 left-0 bottom-0 right-0 bg-[#00000006] z-50 "
                      onClick={()=> setOpen(false)}
                      />
                      </>
                      )}
              <div className="pt-2 xl:pt-5 px-3 flex-auto">
                <div className="tab-content tab-space">{children}</div>
              </div>
            </div>
     </div>
    </>
   
  );
}

export default Layout;

