import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../components/Layout";
import totalAssets from "../assets/images/dashboard/totalAssets.svg";
import freeAssestsIcon from "../assets/images/dashboard/freeAssets.svg";
import leasedAssets from "../assets/images/dashboard/leasedAssets.svg";

import assetsInBreakdown from "../assets/images/dashboard/assetsInBreakdown.svg";
import usageHours from "../assets/images/dashboard/usageHours.svg";
import actualhours from "../assets/images/dashboard/actualhours.svg";
import totalOperators from "../assets/images/dashboard/totalOperators.svg";
import { allDashboardData } from "../apis/dashboard/all_data_for_dashboard";
import { useQuery } from "@tanstack/react-query";
import CategoryTable from "../components/tables/DashboardTable/CategoryTable";
import Loader from "../components/Loader";
import { NavbarContext } from "../Context/NavbarContext";
function Dashboard() {
  const [, setNavState] = useContext(NavbarContext);
  const navigate = useNavigate();
  const handleReload = () => {
    document.location.reload();
  };

  const {
    isLoading,
    isError,
    data: allData,
    error,
  } = useQuery({ queryKey: ["dashboard_data"], queryFn: allDashboardData });

  useEffect(() => {
    setNavState(1);
  }, []);

  if (isLoading) {
    return (
      <Layout>
        <Loader />
      </Layout>
    );
  }

  if (isError) {
    return (
      <span className="text-center items-center  flex flex-col justify-center text-white font-bold text-6xl mt-[20%] ">
        Something Wrong !!!!!!
      </span>
    );
  }

  const Data = [
    {
      title: "Total Assets",
      
      route: "/assets",
      data: allData?.total_assets,
    },
    {
      title: "Free Assets",
      
      route: "/assets",
      data: allData?.total_free_assets,
    },
    {
      title: "Asset Leased",
      
      route: "/lease",
      data: allData?.total_assets_active_in_lease,
    },

    {
      title: "Asset under Breakdown",
      
      route: "#",
      data: allData?.number_asset_break_down_in_maintenance,
    },
    {
      title: "Total Operators",
      
      route: "#",
      data: allData?.total_number_operator,
    },
  ];

  return (
    <Layout>
      <>
        <div className="flex flex-col text-xs m-1" id="dashboard">
          {/* Header */}

          <div className="grid grid-cols-5 gap-6 m-4">
            {Data.map((data, index) => (
              <>
                <div
                  className="h-[100px]  rounded-xl  border dark:border-none px-4 py-2 dark:bg-slate-600"
                  key={index}
                >
                  <div className="flex justify-between border-l-2 border-[#12BED3] pl-2">
                  <h1 className="flex flex-col">
                  <span className="font-bold text-sm ">{index >= 0 && index <= 3 ? "Assets":"Operators"}</span>
                    {data.title}
                  </h1>
                  {/* <img src={data.imgUrl} alt="" className="my-2 h-4 w-4" /> */}
                  
                  </div>
                  {index === 5 || index === 4 ? (
                    <>
                      <p className="my-2 text-xs font-bold  ml-3">
                        {data.data}
                      </p>
                    </>
                  ) : index === 0? (
                  <>
                   <p className="my-2 text-xs font-bold ml-3">{data.data - allData?.sale_asset}+{allData?.sale_asset}</p>
                  </>
                  ) : (
                    <>
                    
                      <p className="my-2 text-xs font-bold ml-3">{data.data}</p>
                    </>
                  )}

               
                  {index >= 0 && index <= 2 && (
                    <Link to={data.route} className="text-xs underline ml-3">
                      See All Assets
                    </Link>
                  )}
                </div>
              </>
            ))}
          </div>
        </div>
        <div className="h-12 border fixed bottom-0 left-0 right-0 z-[9999] bg-white items-center flex justify-end">
          <button
            className="text-xs ml-20 rounded-full border w-[100px] h-7"
            onClick={handleReload}
          >
            Reload Data
          </button>
        </div>
      </>
      <CategoryTable />
    </Layout>
  );
}

export default Dashboard;
