import axios from "axios";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { setBaseUrl } from "../../../config";
import { TableLoader } from "../../TableLoader";
import ReactPaginate from "react-paginate";
import { CurrentItems } from "./CurrentItems";
import NewAssetBtn from "../../Buttons/NewAssetBtn";
import HistoryBtn from "../../Buttons/HistoryBtn";
import ExcelReportBtn from "../../Buttons/ExcelReportBtn";
import MISReportBtn from "../../Buttons/MISReportBtn";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { CiFilter } from "react-icons/ci";
import { PermissionContext } from "../../../Context/PermissionsContext";
import { IoMdAdd } from "react-icons/io";
import Loader from "../../Loader";
import { IoIosClose } from "react-icons/io";
import { useSearchAssets } from "../../../hooks/useSearchAssets";
import AssetSearch from "../../Search/AssetSearch";
import { capitalizeFirstLetter } from "../../Modals/AssetDetails/Tabs/FormatDate";
import { CiViewTable } from "react-icons/ci";
import { BiSortAlt2 } from "react-icons/bi";

function AssetTable({ showCurrentTab, setShowCurrentTab }) {
  const [perms, setPerms] = useContext(PermissionContext);

  // const [itemOffset, setItemOffset] = useState(0);
  // const [items, setItems] = useState(1);
  const {
    setFilter,
    searchValue,
    handleSearch,
    handleSearchClick,
    showSearchDiv,
    loader,
    setLoader,
    deleted,
    setDeleted,
    assetDetails,
    getAllAssets,

    showAllData,
    getSearchList,
    itemOffset,
    setItemOffset,
  } = useSearchAssets(setShowCurrentTab);

  let selected = [
    "all Assets",
    "active",
    "inactive",
    "never assigned",
    "expiring",
    "expired",
  ];
  
  const [selectedItem, setSelectedItem] = useState("all Assets");
  const [openFilter, setOpenFilter] = useState(false);
  const [openTable, setopenTable] = useState(false);
  const [openFilterStatus, setFilterStatus] = useState(false);
  const [openYom, setOpenYom] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const [openSold, setOpenSold] = useState(null);
  const uniqueYears = [...new Set(assetDetails.map((details) => details.yom))];
  const uniqueCategory = [
    ...new Set(assetDetails.map((details) => details.category)),
  ];
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  let tableArray = [10, 50, 100, 150];
  const [selectTableData, setSelectTableData] = useState(null);
  // const [ascending, setAscending] = useState(null);
  // const [descending, setDescending] = useState(null);
  const [device, setDevice] = useState("all device");
  const [open, setOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  
  // header of the table
  const sortHeader = [
    "Asset No",
    "Brand & Model",
    "Serial No",
    "Lease Status",
    "Category",
    "YOM",
    "Created On",
  ];
  
  const [openSort, setOpenSort] = useState(false);
  // sort names are storing
  const [sort, setSort] = useState(null);
  // for input in sorting
  const [inputSearch, setInputSearch] = useState("");

  // sorting the data in ascending order using the whole filter
  const [sortOrder, setSortOrder] = useState("asc");

  // Filtered header based on search input
  const filteredHeaders = sortHeader.filter((header) =>
    header.toLowerCase().includes(inputSearch.toLowerCase())
  );


  // Functions to implement Search in the filter 
  const [inputFilter, setInputFilter] = useState("")
  const buttons = [
    {
      label: "Status",
      onClick: () => {
        setFilterStatus(!openFilterStatus);
        setOpenCategory(false);
        setOpenYom(false);
      },
    },
    {
      label: "YOM",
      onClick: () => {
        setOpenYom(!openYom);
        setOpenCategory(false);
        setFilterStatus(false);
      },
    },
    {
      label: "Category",
      onClick: () => {
        setOpenCategory(!openCategory);
        setOpenYom(false);
        setFilterStatus(false);
      },
    },
    {
      label: "Device Installed",
      onClick: () => {
        setDevice("device installed");
        setOpenCategory(false);
        setOpenYom(false);
        setFilterStatus(false);
        setOpenFilter(false);
      },
    },
    {
      label: "Sold Machines",
      onClick: () => {
        setOpenSold(true);
        setOpenFilter(false);
      },
    },
    // {
    //   label: "filter",
    //   onClick: null, // Disabled button, no action
    //   disabled: true,
    //   icon: <IoMdAdd />,
    // },
  ];

  // Filter the buttons based on the input value
  const filteredButtons = buttons.filter((button) =>
    button.label.toLowerCase().includes(inputFilter.toLowerCase())
  );


  return (
    <>
      <div className="px-2 flex  flex-col md:flex-row border-b  pb-2 justify-between items-center md:space-y-0">
        <div className="flex gap-4 text-xs justify-between z-20">
          <button
            className="border rounded-lg h-6 w-16 justify-center cursor-pointer items-center flex gap-1"
            onClick={() => setopenTable(true)}
          >
            <CiViewTable />
            table
          </button>

          {selectTableData && (
            <>
              <button className="bg-[#EEE] px-2 flex items-center rounded cursor-pointer">
                {selectTableData}
                <IoIosClose
                  className="h-4 w-4"
                  onClick={() => setSelectTableData(null)}
                />
              </button>
            </>
          )}

          {selectedItem !== "all Assets" && (
            <>
              <button className="bg-[#EBEBEB] px-2 flex items-center rounded">
                {selectedItem}
                <IoIosClose
                  className="h-4 w-4"
                  onClick={() => setSelectedItem("all Assets")}
                />
              </button>
            </>
          )}

          {selectedYear && (
            <>
              <button className="bg-[#EBEBEB] px-2 flex items-center rounded">
                {selectedYear}
                <IoIosClose
                  className="h-4 w-4"
                  onClick={() => setSelectedYear(null)}
                />
              </button>
            </>
          )}

          {selectedCategory && (
            <>
              <button className="bg-[#EBEBEB] px-2 flex items-center rounded">
                {selectedCategory}
                <IoIosClose
                  className="h-4 w-4"
                  onClick={() => setSelectedCategory(null)}
                />
              </button>
            </>
          )}

          {openSold && (
            <>
              <button className="bg-[#EBEBEB] px-2 flex items-center rounded">
                Sold
                <IoIosClose
                  className="h-4 w-4"
                  onClick={() => setOpenSold(null)}
                />
              </button>
            </>
          )}

          {device !== "all device" && (
            <>
              <button className="bg-[#EBEBEB] px-2 flex items-center rounded">
                {device}
                <IoIosClose
                  className="h-4 w-4"
                  onClick={() => setDevice("all device")}
                />
              </button>
            </>
          )}

          {openTable && (
            <>
              <div
                className="absolute mt-10 z-[9999] left-0 text-xs
                flex flex-col justify-center 
               bg-white dark:bg-[#070e18] w-[80px] shadow-2xl rounded-lg h-fit items-center"
              >
                {tableArray.map((table) => (
                  <>
                    {" "}
                    <button
                      key={table}
                      className="hover:bg-[#EBEBEB] w-full  py-1 dark:hover:text-black "
                      onClick={() => setSelectTableData(table)}
                    >
                      {table}
                    </button>{" "}
                  </>
                ))}
              </div>
              <div
                className="fixed top-0 left-0 bottom-0 right-0 bg-[#00000006] z-50 "
                onClick={() => setopenTable(false)}
              />
            </>
          )}
          {openFilter && (
            <>
              <div
                className="absolute mt-0 z-[9999] right-[17%] flex flex-col justify-center pt-2 pb-2 shadow-2xl
                  bg-white dark:bg-[#070e18] w-[150px] rounded-lg h-fit items-center"
              >
                <input
                  type="text"
                  className="bg-transparent w-[130px] h-7 rounded-md text-xs"
                  placeholder="Search..."
                  value={inputFilter}
                  onChange={(e) => setInputFilter(e.target.value)}
                />

                {filteredButtons.map((button, index) => (
                  <button
                    key={index}
                    className={`hover:bg-[#EEE] mt-2  w-[130px] rounded-md py-2 dark:hover:text-black=`}
                    onClick={button.disabled ? null : button.onClick}
                  >
                    {button.icon && <span className="mr-2">{button.icon}</span>}
                    {button.label}
                  </button>
                ))}

                <button
                  className="flex items-center justify-center rounded-md py-2 cursor-not-allowed hover:bg-[#EEE] w-[130px] "
                >
                  <IoMdAdd />
                  filter
                </button>
              </div>

              {openFilterStatus && (
                <>
                  <div
                    onMouseLeave={() => setFilterStatus(false)}
                    className="absolute mt-9 z-[9999] right-[24.5%]
                    flex flex-col justify-center  pt-6 shadow-2xl
                  bg-white dark:bg-[#070e18] dark:border-none dark:shadow-md   dark:shadow-[#767676] w-[150px] border rounded-lg h-fit items-center"
                  >
                    {selected.map((select) => {
                      return (
                        <>
                          <button
                            className={`hover:bg-[#EEE] dark:hover:text-black w-[125px] rounded-md px-2  py-2 ${
                              selectedItem === select
                                ? "bg-[#EEE] dark:text-black"
                                : ""
                            }  `}
                            onClick={() => {
                              setSelectedItem(select);
                              setFilterStatus(false);
                              setOpenFilter(false);
                            }}
                            key={select}
                          >
                            {capitalizeFirstLetter(select)}
                          </button>
                        </>
                      );
                    })}

                    <button className="flex items-center justify-start py-2">
                      <IoMdAdd />
                      Status
                    </button>
                  </div>
                </>
              )}

              {openYom && (
                <>
                  <>
                    <div
                      onMouseLeave={() => setOpenYom(false)}
                      className="absolute mt-0 z-[9999] right-[29%]
                    flex flex-col justify-center pt-4  shadow-2xl  overflow-y-auto
                  bg-white dark:bg-[#070e18] dark:border-none dark:shadow-md   
                  dark:shadow-[#767676] w-[150px]  rounded-lg  items-center"
                    >
                      {uniqueYears.map((year) => (
                        <button
                          key={year}
                          className="hover:bg-[#EEE] rounded-md  dark:hover:text-black w-[130px]  py-2"
                          onClick={() => setSelectedYear(year)}
                        >
                          {year}
                        </button>
                      ))}
                    </div>
                  </>
                </>
              )}

              {openCategory && (
                <>
                  <>
                    <div
                      onMouseLeave={() => setOpenCategory(false)}
                      className="absolute mt-9 z-[9999] right-[25.5%]
                      flex flex-col justify-center  pt-6 shadow-2xl
                      bg-white dark:bg-[#070e18] dark:border-none dark:shadow-md   
                      dark:shadow-[#767676] w-[150px] border rounded-lg h-fit pb-6 items-center"
                    >
                      {uniqueCategory.map((category) => (
                        <button
                          key={category}
                          className="hover:bg-[#EBEBEB] dark:hover:text-black w-full py-2"
                          onClick={() => setSelectedCategory(category)}
                        >
                          {category}
                        </button>
                      ))}
                    </div>
                  </>
                </>
              )}

              <div
                className="fixed top-0 left-0 bottom-0 right-0 bg-[#00000006] z-50 "
                onClick={() => setOpenFilter(false)}
              />
            </>
          )}

          {/* <div className="">filtered data</div> */}
        </div>

        <div className="flex flex-row  justify-between items-center ">
          <div
            className={`hover:bg-[#EEE] rounded-md p-1.5 borde text-[#090909] font-extrabold justify-center  items-center flex gap-1 cursor-pointer ${
              openFilter && "bg-[#EEE]"
            }`}
            onClick={() => setOpenFilter(true)}
          >
            {/* filter */}
            <CiFilter className="w-4 h-4 " />
          </div>
          {/* Sorted Data */}
          {sort && (
            <>
          
       
              <button className="text-xs bg-[#EEE] rounded-md px-1 py-1 flex items-center justify-center">
                {sort}
                <IoIosClose
                  className="w-4 h-4 cursor-pointer"
                  onClick={() => setSort(null)}
                />
              </button>
            </>
          )}

          {/* Sorting Suggestions */}
          
          <button
            className="p-1.5 rounded-md hover:bg-[#EEE] borde"
            onClick={() => setOpenSort(!openSort)}
          >
            <BiSortAlt2 className="h-4 w-4 rounded  text-[#000000]" />
          </button>
          {openSort && (
            <>
              <div className="absolute text-xs w-[200px] h-fit z-30 top-12 right-[10%] rounded-lg p-2 border bg-white">
                <input
                  type="text"
                  className="bg-transparent w-[180px] h-7 rounded-md text-xs"
                  placeholder="Search..."
                  onChange={(e) => setInputSearch(e.target.value)}
                  value={inputSearch}
                />
                <ul className="mt-2">
                  {filteredHeaders.length > 0 ? (
                    filteredHeaders.map((header) => (
                      <li
                        className="px-2 py-2 hover:bg-[#EEE] rounded-md cursor-pointer"
                        key={header}
                        onClick={() => {
                          setSort(header);
                          setOpenSort(false);
                        }}
                      >
                        {header}
                      </li>
                    ))
                  ) : (
                    <li className="px-2 py-2 text-gray-500">
                      No results found
                    </li>
                  )}
                </ul>
              </div>
              <div
                className={`fixed top-0 left-0 bottom-0 right-0 bg-[#00000006] z-10 ${
                  !openSort && "hidden"
                } `}
                onClick={() => {
                  setOpenSort(false);
                }}
              />
            </>
          )}
          <div className="items-center z-[5000] ">
            {/* <div> */}

            {/* Search Tab */}

            <AssetSearch
              setFilter={setFilter}
              searchValue={searchValue}
              handleSearch={handleSearch}
              handleSearchClick={handleSearchClick}
              open={open}
              setOpen={setOpen}
              setOpenSearch={setOpenSearch}
            />

            {openSearch && showSearchDiv.length > 0 && (
              <div
                className="absolute bg-white
               dark:bg-gray-800 p-2
               border w-[200px] h-[50vh] 
               dark:border-gray-600 
               rounded-2xl hide-scrollbar z-10  
               overflow-y-scroll right-3 mt-5
               shadow transition-all duration-300 ease-in-out text-xs"
              >
                {/* Aconsole.log(showSearchDiv) */}
                {/* <button className="absolute right-0 text-xs font-bold mx-2  text-end">Close</button> */}
                {showSearchDiv.map((item) => (
                  <p
                    onClick={() => handleSearchClick(item)}
                    className="cursor-pointer hover:bg-gray-200 dark:hover:bg-[#EEE] border-b rounded-md p-2 transition duration-300 ease-in-out"
                  >
                    {item}
                  </p>
                ))}
              </div>
            )}
          </div>
          <div
            className={`fixed top-0 left-0 bottom-0 right-0 bg-[#00000006] z-10 ${
              !open && "hidden"
            } `}
            onClick={() => {
              setOpen(false);
              setOpenSearch(false);
            }}
          />
          <NewAssetBtn tabName="asset" />
        </div>
      </div>
      {loader ? (
        <Loader />
      ) : (
        <CurrentItems
          deleted={deleted}
          setDeleted={setDeleted}
          loader={loader}
          setLoader={setLoader}
          assetDetails={assetDetails}
          selected={selected}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          selectTableData={selectTableData}
          selectedCategory={selectedCategory}
          selectedYear={selectedYear}
          device={device}
          openSold={openSold}
          sortHeader={sortHeader}
          sort={sort}
          setSort={setSort}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
        />
      )}
    </>
  );
}

export default AssetTable;
