import React, { useEffect, useState } from 'react'
import { setBaseUrl } from '../config'
import axios from 'axios'
import { formatDate, FormPercentage } from './Modals/AssetDetails/Tabs/FormatDate'

const Timeline = ({ devId, setShowMachine,handleBottomTab }) => {
    const [timeData, setTimeData] = useState(null)
    // console.log(devId)  

    const fetchTimeLineData = async () => {
        try {
            const response = await axios.get(`${setBaseUrl}/asset/get-device-timeline`, 
                 {
                    headers: {
                        "x-access-tokens": sessionStorage.getItem("token"),
                        'device-id': devId,
                    }
                }
            )

            if(!response.status === 200) {
                throw new Error('Network response was not ok')
            }
            const items = response.data
            setTimeData(items)
            // console.log(timeData)
            // console.log(response.data)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {    
        fetchTimeLineData()
    }, [])

    const [hover, setHover] = useState(false)
    return (
        <>
        {/* <div className="overflow-x-auto mt-5 w-full">
            {timeData? 
            <>
            <table className=" divide-y divide-gray-200">
                <thead className="bg-gray-50 dark:bg-slate-900">
                    <tr>
                        <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 dark:text-white  uppercase tracking-wider">
                            Date
                        </th>
                       
                        <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 dark:text-white  uppercase tracking-wider">
                            Start Time
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 dark:text-white  uppercase tracking-wider">
                            End Time
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 dark:text-white  uppercase tracking-wider">
                            Duration
                        </th>
                    </tr>
                </thead>
                
                <tbody className="bg-white dark:bg-slate-800 divide-y divide-gray-200">
                    
                    {timeData && Object.keys(timeData).map((date) => (
                         timeData[date]?.map((item, index) => 
                         {   
                            let start = item[1].start_time.split(" ")[1]
                            let end = item[1].end_time.split(" ")[1]


                            const startTime = start.split(":")
                            const endTime = end.split(":")
                            const durationTime = item[0].split(":")

                            const formattedStart = `${startTime[0]}:${startTime[1]}`
                            const formattedEnd = `${endTime[0]}:${endTime[1]}`
                              const formattedDuration = `${durationTime[0]}:${durationTime[1]}:${durationTime[2].split('.')[0]}`

                            return (
                            <tr key={`${date}-${index}`}>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    {formatDate(date)}
                                </td>
                             
                                <td className="px-6 py-4 whitespace-nowrap">
                                    {formattedStart}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    {formattedEnd}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    {formattedDuration}
                                </td>
                            </tr>
                        )
                    }
                    )
                    ))}
                </tbody>
            </table>
            </> : 
            <>
            <div className="text-center mt-20">Data is not generated!!</div>
            </>}
        </div> */}

        <div className=" w-[100vh]  mt-1">
            <h1 className="text-xs font-bold py-2 mx-2">Device Timeline Details</h1>
            <div className="border rounded-2xl h-[260px] hide-scrollbar overflow-y-auto" onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            {hover && 
            <>
            <div className="bg-black absolute  h-[260px] w-[100vh] rounded-2xl z-50 bg-opacity-40 flex justify-center items-center">
                <button className="bg-black text-white rounded-full w-[120px] h-7 text-xs" onClick={() => {setShowMachine(true); handleBottomTab(4); }}>Show More</button>
            </div>
            </>}
            {timeData ?     
                <>
               <table className='text-center text-xs border-separate border-spacing-y-0   w-full relative'>
                <thead className='h-10 sticky  bg-[#efefef] dark:bg-gray-700  '>
                    <tr className="">
                        <td className='rounded-tl-2xl'>Start Date</td>
                        <td>Start Time</td>
                        <td>End Time</td>
                        <td className='rounded-tr-2xl'>Duration</td>
                    </tr>
                </thead>
                <tbody className="">
                    
                    {timeData && Object.keys(timeData).reverse().map((date) => (
                         timeData[date]?.reverse().map((item, index) => 
                         {   
                            let start = item[1].start_time.split(" ")[1]
                            let end = item[1].end_time.split(" ")[1]


                            const startTime = start.split(":")
                            const endTime = end.split(":")
                            const durationTime = item[0].split(":")

                            const formattedStart = `${startTime[0]}:${startTime[1]}`
                            const formattedEnd = `${endTime[0]}:${endTime[1]}`
                              const formattedDuration = `${durationTime[0]}:${durationTime[1]}:${durationTime[2].split('.')[0]}`

                            return (
                            <tr key={`${date}-${index}`} className='h-8 items-center '>
                                <td className="border-b">
                                    {formatDate(date)}
                                </td>
                             
                                <td className="border-b">
                                    {formattedStart}
                                </td>
                                <td className="border-b">
                                    {formattedEnd}
                                </td>
                                <td className="border-b">
                                    {formattedDuration}
                                </td>
                            </tr>
                        )
                    }
                    )
                    ))}
                </tbody>
               </table>
                </> 
                :
                 <>
                 <div className="text-center mt-10 text-xs">Data is not generated!!</div>
                 </>
            }


            </div>
        </div>
    </>
    )
}

export default Timeline
