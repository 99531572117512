import React, { useEffect, useState } from "react";
import { get_log_table_data } from "../../../apis/LeaseApis";

import { Accordion } from "flowbite-react";
import { convertToHoursAndMinutes, formatDate, formatMonth } from "../AssetDetails/Tabs/FormatDate";
import YeaarPicker from "./YeaarPicker";

function LogTable({ rso_id, setRunDuration }) {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoadin] = useState(false);
  const [open, setOpen] = useState(false);
  
  const getLogTableData = async () => {
    try {
      setLoadin(true);
      const { data } = await get_log_table_data(rso_id);
      setTableData(data);
      setLoadin(false);
      // console.log(data)
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getLogTableData();
  }, []);


  let table = tableData[0]
  let keys = []
if(tableData.length !== 0) {
 keys =  Object?.keys(table);
}
  
    useEffect(() => {
      keys.forEach((key) => {
        table[key]?.forEach((item) => {
          console.log(item?.x_studio_breakdown_time);
        });
      });
    }, [tableData]);

    let total_amount = 0  
let amount = tableData[1]
let amounts = []
if(amount !== undefined || null) {
    // console.log(Object?.values(amount))
    amounts = Object.values(amount)
    // console.log(amounts)
    for(let i = 0; i < amounts.length; i++) {
      total_amount = total_amount + amounts[i];
    }
  }
  
  console.log(total_amount)
  setRunDuration(convertToHoursAndMinutes(total_amount))


  if (loading) {
    return (
      <div className="w-[175vh] h-[50vh] overflow-x-auto overflow-y-auto py-12 text-xs">
        {loading && (
          <div className="flex mt-9 justify-center  text-center items-center h-[20vh]">
            <div className="border h-64 p-5 rounded-2xl shadow-2xl w-[100%] max-w-full mb-5 animate-pulse ">
              <div className="animate-pulse">
                <div className=" h-14 bg-gray-400 rounded-lg mb-4"></div>
                <div className="h-10 bg-gray-300 rounded-lg mb-4"></div>
                <div className="h-10 bg-gray-300 rounded-lg mb-4"></div>
                {/* <div className="h-10 bg-gray-300 rounded-lg mb-4"></div> */}
                <div className="h-10 bg-gray-300 rounded-lg mb-4"></div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    // const keys = Object.keys(tableData)

    return (
      <>
        <div className="mt-7">
        <div className="border rounded-xl bg-[#EEE] text-xs w-fit p-2">Total Working Time {convertToHoursAndMinutes(total_amount)} </div>

          {/* <YeaarPicker month={formattedMonth} year={year} /> */}

          {keys.map((key, index) => {
            const { formattedMonth, year } = formatMonth(key);
            // console.log(amount[key])

      

            return (
              <>
              
                <Accordion
                  className="w-[calc(200vh-12%)] border text-xs mt-4"
                  key={index}
                >
                  <Accordion.Panel>
                    <Accordion.Title className="focus:outline-none focus:border-none focus:ring-0">
                      {" "}
                      <div className="flex justify-between items-center space-x-20">
                        <div >  {formattedMonth}, {year}</div >
                        <div >Total Time: {convertToHoursAndMinutes(amount[key])}</div >
                      </div>
                  
                       
                    </Accordion.Title>

                    <Accordion.Content>
                      <div className="hide-scrollbar  overflow-x-auto overflow-y-auto ">
                        <table className="table-auto items-center text-center w-[300vh] overflow-x-auto">
                          <thead className="bg-[#EEE] h-10">
                            <tr>
                              {/* <th className="">Odoo Id</th> */}
                              <th className="">Date</th>
                              <th className="">Day Type</th>
                            
                              <th className="">Time In</th>
                              <th className="">Time Out</th>
                              <th className="">Total Time</th>
                              <th className="">Overtime</th>
                              <th className="">Time Adjust</th>
                              
                              <th className="">Odoo Bill Amount</th>
                              <th className="">Overtime Amount</th>
                             
                              <th className="">Operator 1</th>
                              {/* <th className="">Rental Register No</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {loading === false ? (
                              table[key].map((item, i) => (
                                <tr key={i} className="h-10 border-b hover:bg-[#EEEE]">
                                  {/* <td className="">{item.id}</td> */}
                                  <td className="">
                                    {formatDate(item.x_studio_date)}
                                  </td>
                                  <td className="">{item.x_studio_day_type}</td>
                                 
                                  <td className="">{item.x_studio_time_in}</td>
                                  <td className="">{item.x_studio_time_out}</td>
                                  <td className="">
                                    {item.x_studio_total_time}
                                  </td>
                                  <td className="">{item.x_studio_overtime}</td>
                                  <td className="">
                                    {item.x_studio_time_adjust}
                                  </td>
                                
                                  <td className="">
                                    ₹
                                    {parseFloat(
                                      item.x_studio_normal_bill_amount
                                    ).toFixed(2)}
                                  </td>
                                  <td className="">
                                    ₹
                                    {parseFloat(
                                      item.x_studio_overtime_amount
                                    ).toFixed(2)}
                                  </td>
                                
                                  <td className="">
                                    {item.x_studio_operator1}
                                  </td>
                                  {/* <td className="">{item.x_studio_rental_register_no}</td> */}
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="16" className="">
                                  Loading...
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </Accordion.Content>
                  </Accordion.Panel>
                </Accordion>
              </>
            );
          })}
        </div>
      </>
     
    );
  }
}

export default LogTable;
