import React, { useState } from "react";
import { currency } from "./FormatDate";

const AssetDashboards = ({ commercialDetails }) => {
  const { time_by_month, total_cost, total_cost_by_month, total_time } =
    commercialDetails?.odoo_cost || {};
  const months = Object.keys(time_by_month);

  const monthMap = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec",
  };

  // Extract unique years and months
  const uniqueYears = [...new Set(months?.map((date) => date?.split("-")[0]))];
  const [selectedYear, setSelectedYear] = useState(uniqueYears[0]);

  const handleYearSelection = (year) => {
    setSelectedYear(year);
  };

  const calculateYearTotals = (year) => {
    let totalNormal = 0;
    let totalOvertime = 0;
    let totalAmount = 0;

    months
      .filter((month) => month?.startsWith(year))
      .forEach((month) => {
        totalNormal += total_cost_by_month[month][0];
        totalOvertime += total_cost_by_month[month][1];
        totalAmount += total_cost_by_month[month][0] + total_cost_by_month[month][1];
      });

    return {
      totalNormal,
      totalOvertime,
      totalAmount,
    };
  };

  return (
    <div className="md:w-full text-xs px-6 py-2 border rounded-xl bg-white">
      <h1 className="text-base font-bold text-[#7e7e7e] mb-4">
       Revenue generated
      </h1>

      {/* Year Buttons */}
      <div className="flex p-2 gap-4">
        {uniqueYears?.map((year) => (
          <button
            key={year}
            className={`h-7 w-[67px] rounded ${
              selectedYear === year
                ? "bg-[#999898] text-white"
                : "bg-[#EEE] text-[#898989]"
            }`}
            onClick={() => handleYearSelection(year)}
          >
            {year}
          </button>
        ))}
      </div>

      <div className="mt-6" />
      {/* Display Tables for Selected Year */}
      {uniqueYears?.map((year) => {
        const yearTotals = calculateYearTotals(year);
        return (
          <>
         
          <div key={year} className="overflow-x-auto rounded-2xl w-[110vh] 
                          [&::-webkit-scrollbar]:w-2
                [&::-webkit-scrollbar-track]:rounded-[0]
                [&::-webkit-scrollbar-track]:bg-white
                [&::-webkit-scrollbar-thumb]:rounded-[5px]
                [&::-webkit-scrollbar-thumb]:bg-[#EEE]
                [&::-webkit-scrollbar-thumb]:mt-6
                dark:[&::-webkit-scrollbar-track]:bg-neutral-700
                dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500
          ">
            {selectedYear === year && (
              <table className="min-w-max w-full table-auto 
              
              ">
                {/* Table Header (Months) */}
                <thead>
                  <tr>
                    <th className="p-2 border-b bg-[#c9c6c6] sticky left-0 z-10 ">
                      Details
                    </th>
                    {months?.filter((month) => month?.startsWith(year))
                      .map((month) => {
                        const monthKey = month?.split("-")[1];
                        const monthName = monthMap[monthKey];
                        return (
                          <th
                            key={month}
                            className="p-2 border-b bg-[#c9c6c6] text-left"
                          >
                            {monthName}
                          </th>
                        );
                      })}
                    <th className="p-2 border-b bg-[#c9c6c6] sticky right-0 z-10 ">
                      Total
                    </th>
                  </tr>
                </thead>

                {/* Table Body */}
                <tbody>
                  {/* Normal Cost */}
                  <tr>
                    <td className="p-2 border-b font-semibold bg-[#EEE] sticky left-0 z-10 ">
                      Normal Amount
                    </td>
                    {months?.filter((month) => month.startsWith(year))?.map((month) => (
                        <td key={month} className="p-2 border-b">
                          ₹{currency(total_cost_by_month[month][0].toFixed(2))}
                        </td>
                      ))}
                    <td className="p-2 border-b sticky right-0 z-10 bg-[#ebe9e9]">
                      ₹{currency(yearTotals.totalNormal.toFixed(2))}
                    </td>
                  </tr>

                  {/* Overtime Cost */}
                  <tr>
                    <td className="p-2 border-b font-semibold bg-[#EEE] sticky left-0 z-10 bg-">
                      Overtime Amount
                    </td>
                    {months?.filter((month) => month.startsWith(year))?.map((month) => (
                        <td key={month} className="p-2 border-b">
                          ₹{currency(total_cost_by_month[month][1].toFixed(2))}
                        </td>
                      ))}
                    <td className="p-2 border-b sticky right-0 z-10 bg-[#ebe9e9]">
                      ₹{currency(yearTotals.totalOvertime.toFixed(2))}
                    </td>
                  </tr>

                  {/* Total Cost */}
                  <tr>
                    <td className="p-2 border-b font-semibold bg-[#EEE] text-[#3671d9] sticky left-0 z-10 bg-">
                      Total Amount
                    </td>
                    {months
                      .filter((month) => month.startsWith(year))
                      .map((month) => (
                        <td key={month} className="p-2 border-b">
                          ₹{currency(
                            (
                              total_cost_by_month[month][0] +
                              total_cost_by_month[month][1]
                            ).toFixed(2)
                          )}
                        </td>
                      ))}
                    <td className="p-2 border-b sticky right-0 z-10 bg-[#ebe9e9]">
                      ₹{currency(yearTotals.totalAmount.toFixed(2))}
                    </td>
                  </tr>

                    {/* Normal Time */}
                    <tr>
                              <td className="p-2 border-b font-semibold bg-[#EEE] sticky left-0 z-10">Normal Time</td>
                              {months
                                .filter((month) => month.startsWith(year))
                                .map((month) => (
                                  <td key={month} className="p-2 border-b">
                                    {time_by_month[month][0]} hrs
                                  </td>
                                ))}
                              <td className="p-2 border-b sticky right-0 z-10 bg-[#ebe9e9]">--</td>
                            </tr>

                            {/* Overtime */}
                            <tr>
                              <td className="p-2 border-b font-semibold bg-[#EEE] sticky left-0 z-10">Overtime</td>
                              {months
                                .filter((month) => month.startsWith(year))
                                .map((month) => (
                                  <td key={month} className="p-2 border-b">
                                    {time_by_month[month][1]} hrs
                                  </td>
                                ))}
                              <td className="p-2 border-b sticky right-0 z-10 bg-[#ebe9e9]">--</td>
                            </tr>

                            {/* Total Time */}
                            <tr>
                              <td className="p-2 border-b font-semibold bg-[#EEE] sticky left-0 z-10 text-[#3671d9]">Total Time</td>
                              {months
                                .filter((month) => month.startsWith(year))
                                .map((month) => (
                                  <td key={month} className="p-2 border-b">
                                    {time_by_month[month][0] + time_by_month[month][1]} hrs
                                  </td>
                                ))}
                              <td className="p-2 border-b sticky right-0 z-10 bg-[#ebe9e9]">--</td>
                            </tr>

                            {/* Spares */}
                            <tr>
                              <td className="p-2 border-b font-semibold bg-[#EEE] sticky left-0 z-10">Spares</td>
                              {months
                                .filter((month) => month.startsWith(year))
                                .map((month) => (
                                  <td key={month} className="p-2 border-b">--</td>
                                ))}
                              <td className="p-2 border-b  sticky right-0 z-10 bg-[#ebe9e9]">--</td>
                            </tr>

                            {/* Transportation Cost */}
                            <tr>
                              <td className="p-2  font-semibold bg-[#EEE] sticky left-0 z-10 rounded-bl-2xl">Transportation Cost</td>
                              {months
                                .filter((month) => month.startsWith(year))
                                .map((month) => (
                                  <td key={month} className="p-2">--</td>
                                ))}
                              <td className="p-2  sticky right-0 z-10 bg-[#ebe9e9] rounded-br-2xl ">--</td>
                            </tr>
                </tbody>
              </table>
            )}
          </div>

          </>
        );
      })}

      {/* Total Section */}
      <div className="mt-6 bg-white rounded-lg border p-4">
        <div className="flex justify-between py-2 border-b">
          <h2 className="font-semibold text-gray-700">Total Time</h2>
          <p>{total_time} hrs</p>
        </div>
        <div className="flex justify-between py-2">
          <h2 className="font-semibold text-gray-700">Total Cost</h2>
          <p>₹{currency(total_cost.toFixed(2))}</p>
        </div>
      </div>
    </div>
  );
};

export default AssetDashboards;
