import axios from "axios";
import React, { useEffect, useState } from "react";
import { setBaseUrl } from "../../../config";
import { TableLoader } from "../../TableLoader";
import { useNavigate } from "react-router-dom";
import { PermissionContext } from "../../../Context/PermissionsContext";
import { useContext } from "react";
import telematicsLogo from "../../../assets/images/telematics/telematics.svg";
import { IoFilter } from "react-icons/io5";
import {
  capitalizeFirstLetter,
  formatDate,
} from "../../Modals/AssetDetails/Tabs/FormatDate";
import "./currentitems.css";
export const CurrentItems = ({
  assetDetails,
  setLoader,
  deleted,
  setDeleted,
  selected,
  selectedItem,
  selectTableData,

  selectedCategory,
  selectedYear,
  device,
  openSold,
  sortHeader,
  sort,
  setSort,
  sortOrder,
  setSortOrder,
}) => {
  
  const navigate = useNavigate();
  const [tableAnimation, setTableAnimation] = useState(false);
  const openAssetDetails = (id, telematics = false) => {
    if (telematics) navigate("/assets/AssetDetails/" + id + "/" + telematics);
    else navigate("/assets/AssetDetails/" + id);
  };

  const openAssetUpdateTab = (id) => {
    navigate("updateAsset/" + id);
  };
  const deleteAsset = async (id) => {
    try {
      setLoader(true);
      const { data } = axios.delete(
        `${setBaseUrl}/asset/delete`,
        {
          id: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": sessionStorage.getItem("token"),
          },
        }
      );
      setDeleted((prev) => !prev);
    } catch (error) {
      console.error(error);
      alert(error.message);
    } finally {
      setLoader(false);
      return;
    }
  };
  useEffect(() => {
    // console.log(assetDetails)
    setTimeout(() => {
      setTableAnimation(true);
    }, 400);
  }, [deleted]);

  
  const sortedAssetDetails = [...assetDetails].sort((a, b) => {
    if (!sort) return 0; // No sorting if sort is null

    const aValue = a[sort.toLowerCase().replace(" & ", "_").replace(" ", "_")] || ""; // Convert the sort string to match the object key
    const bValue = b[sort.toLowerCase().replace(" & ", "_").replace(" ", "_")] || ""; 

    if (aValue < bValue) {
      return sortOrder === "asc" ? -1 : 1;
    }
    if (aValue > bValue) {
      return sortOrder === "asc" ? 1 : -1;
    }
    return 0;
  })

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };
  

  return (
    <>
      <div className="  w-full h-[calc(100vh-7.5rem)] my-4 overflow-hidden border hide-scrollbar rounded-xl max-lg:overflow-x-auto overflow-y-auto">
        {assetDetails === undefined || assetDetails.length === 0 ? (
          <>
            <div className="text-xs h-[50vh] flex rounded-lg justify-center items-center dark:text-white">
              No Data available
            </div>
          </>
        ) : (
          <>
            <div className="">
              <table
                className={`table ${
                  tableAnimation ? "show-rows" : ""
                }  border-separate border-spacing-y-0  text-xs  w-full relative `}
              >
                <thead className="h-10 sticky bg-[#efefef] dark:bg-gray-700">
                  <tr className="">
                  {sortHeader.map((header) => (
                  <th
                    key={header}
                    className="cursor-pointer"
                    onClick={() => {
                      if (sort === header) {
                        toggleSortOrder(); // Toggle order if the same header is clicked
                      } else {
                        setSort(header); // Set the new sorting column
                        setSortOrder("asc"); // Default to ascending on new sort column
                      }
                    }}
                  >
                    {header} {sort === header ? (sortOrder === "asc" ? "▲" : "▼") : ""}
                  </th>
                ))}
                    {/* <th className="border-b">Asset No.</th>
                    <th className="border-b">Brand &amp; Model</th>
                    <th className="border-b">Serial No.</th>

                    <th className="border-b">Lease Status</th>
                    <th className="border-b">Category</th>
                    <th className="border-b">YOM</th>
                    <th className="border-b">Created On</th> */}
                    {/* <th className="border-b">Actions</th> */}
                    {/* <th className="border-b">Sold</th> */}
                  </tr>
                </thead>
                <tbody className="bg-white  dark:bg-gray-900">
                  {sortedAssetDetails.map((data, index) => {
                    if (selectTableData >= index || !selectTableData) {
                      return (
                        <>
                          {(selectedItem === "all Assets" ||
                            (`${data?.lease_status}`.indexOf(selectedItem) !==
                              -1 &&
                              selectedItem !== "active") ||
                            (data?.lease_status === "active" &&
                              selectedItem === "active")) &&
                          (device === "all device" ||
                            (device === "device installed" &&
                              data?.device_no) ||
                            `${data?.device_no}`.indexOf(device) !== -1) &&
                          (selectedYear === null ||
                            `${data?.yom}`.indexOf(selectedYear) !== -1 ||
                            selectedYear === `${data?.yom} `) 
                            && (
                              selectedCategory === null || 
                              `${data?.category}`.indexOf(selectedCategory) !== -1 ||
                              selectedCategory === `${data?.category} `
                            ) && (
                              openSold === null || `${data?.is_sold}`.indexOf(openSold) !== -1 || openSold === `${data?.is_sold}`  
                            )
                            ? (
                            <tr
                              key={index}
                              onClick={() => openAssetDetails(data?.id)}
                              className="cursor-pointer text-center items-center hover:bg-[#2222] justify-center h-10"
                            >
                              <td
                                className={`border-b ${
                                  data?.device_no
                                    ? "text-green-500"
                                    : "text-gray-600"
                                }`}
                                onClick={() => openAssetDetails(data?.id)}
                              >
                                { data?.is_sold === true ? (
                                  <img
                                    src="/images/sold.png"
                                    alt=""
                                    className="absolute left-3 h-4 w-6 -rotate-45 mb-5"
                                  />
                                ): data.device_no? <>  <img
                                    src="/images/wifi.svg"
                                    alt=""
                                    className="absolute left-3 h-4 w-4 -rotate-45 mb-7"
                                  /></>:""}
                                {data?.asset_no}
                              </td>
                              <td
                                className="border-b"
                                onClick={() => openAssetDetails(data?.id)}
                              >
                                {data?.brand} {data?.model}
                              </td>
                              <td
                                className="border-b"
                                onClick={() => openAssetDetails(data?.id)}
                              >
                                {data?.serial_no}
                              </td>
                              <td
                                className={`border-b font-bold ${
                                  data?.lease_status === "never assigned"
                                    ? "text-red-600"
                                    : data?.lease_status === "inactive"
                                    ? "text-slate-500"
                                    : data?.lease_status === "expired"? "text-[#511414]" : "text-green-500"
                                }`}
                                onClick={() => openAssetDetails(data?.id)}
                              >
                                {capitalizeFirstLetter(data?.lease_status)}
                              </td>
                              <td
                                className="border-b"
                                onClick={() => openAssetDetails(data?.id)}
                              >
                                {data?.category}
                              </td>
                              <td
                                className="border-b text-green-600 font-bold"
                                onClick={() => openAssetDetails(data?.id)}
                              >
                                {data?.yom}
                              </td>
                              <td
                                className="border-b"
                                onClick={() => openAssetDetails(data?.id)}
                              >
                                {formatDate(data?.created_at * 1000)}
                              </td>
                             
                            </tr>
                          ) : (
                            <></>
                          )}
                        </>
                      );
                    }
                  })}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </>
  );
};
